import { useNotifyErrors, useNotifySuccess, useTableOptions } from '@/composables'
import { fetchDistributors } from '@api/distributor/distributor'
import { ref, watch } from '@vue/composition-api'

const cached = []

export default function useList() {
  const tableList = ref([])

  // Table Handlers
  const tableColumns = [
    { text: 'ID', value: 'id' },
    { text: 'Name', value: 'nicename' },
    { text: 'Slug', value: 'slug' },
    { text: 'Site', value: 'site_id' },
    { text: 'Activated?', value: 'active' },
    { text: 'DATE', value: 'created_at' },
    {
      text: 'ACTIONS',
      value: 'actions',
      align: 'center',
      sortable: false,
    },
  ]

  const options = ref(
    cached[0] || {
      sortBy: ['id'],
      sortDesc: [true],
    },
  )
  const filters = ref({
    search: '',
  })
  const tableTotal = ref(0)
  const loading = ref(false)
  const tableSelectedData = ref([])

  const loadDistributors = () =>
    fetchDistributors(
      useTableOptions(options.value, {
        ...filters.value,

        // email: emailQuery.value,
      }),
    )
      .then(({ data }) => {
        const { records, pagination } = data.data
        tableList.value = records
        tableTotal.value = pagination.total
        loading.value = false
      })
      .catch(error => {
        console.log(error)
      })

  const deleteDistributor = distributor =>
    deleteDistributor(distributor.id)
      .then(() => {
        useNotifySuccess({ content: 'Delete Success' })
        loadDistributors()
      })
      .catch(useNotifyErrors)

  const cachable = [options, filters]

  const cache = () => {
    cachable.forEach((variable, index) => {
      cached[index] = variable.value
    })
  }

  watch(cachable, () => {
    const newPage = options.value.page

    const hasDiff = cachable.find((variable, index) => index > 0 && cached[index] !== variable.value)

    if (newPage >= 2 && hasDiff) {
      options.value.page = 1
    }

    loading.value = true
    tableSelectedData.value = []
    loadDistributors()
    cache()
  })

  return {
    filters,
    tableColumns,
    options,
    tableList,
    tableTotal,
    loading,
    tableSelectedData,
    loadDistributors,
    deleteDistributor,
  }
}
