import axios from '@axios'

const fetchDistributors = queryParams =>
  axios.get('/admin/distributors', {
    params: queryParams,
  })
const fetchDistributor = id => axios.get(`/admin/distributors/${id}`)
const storeDistributor = data => axios.post('/admin/distributors', data)
const updateDistributor = (id, data) => axios.put(`/admin/distributors/${id}`, data)
const deleteDistributor = id => axios.delete(`/admin/distributors/${id}`)

const fetchtDistributorBalance = id => axios.get(`/admin/distributors/${id}/balance`)
const updateDistributorProfile = (id, data) => axios.put(`/admin/distributors/${id}/profile`, data)
const updateDistributorProfilePhoto = (id, data) => axios.post(`/admin/distributors/${id}/profile/photo`, data)

const fetchDistributorSettingsKeyMap = id => axios.get(`/admin/distributors/${id}/settings/category-key-map`)
const fetchDistributorSettings = id => axios.get(`/admin/distributors/${id}/settings`)
const storeDistributorSettings = (id, data) => axios.post(`/admin/distributors/${id}/settings`, data)
const updateDistributorSettings = (id, setting, data) => axios.put(`/admin/distributors/${id}/settings/${setting}`, data)
const deleteDistributorSettings = (id, setting, data) => axios.delete(`/admin/distributors/${id}/settings/${setting}`, data)

const fetchDistributorMedicalCardSettings = id => axios.get(`/admin/distributors/${id}/medical-card-settings`)
const storeDistributorMedicalCardSettings = (id, data) => axios.post(`/admin/distributors/${id}/medical-card-settings`, data)
const updateDistributorMedicalCardSettings = (id, data) => axios.put(`/admin/distributors/${id}/medical-card-settings`, data)

const fetchDistributorFinancialSettings = id => axios.get(`/admin/distributors/${id}/financial-settings`)
const storeDistributorFinancialSettings = (id, data) => axios.post(`/admin/distributors/${id}/financial-settings`, data)
const updateDistributorFinancialSettings = (id, data) => axios.put(`/admin/distributors/${id}/financial-settings`, data)

const fetchDistributorBlacklistSettings = id => axios.get(`/admin/distributors/${id}/blacklist-settings`)
const fetchDistributorWhitelistSettings = id => axios.get(`/admin/distributors/${id}/whitelist-settings`)
const fetchDistributorRewardPointSettings = id => axios.get(`/admin/distributors/${id}/reward-point-settings`)
const storeDistributorRewardPointSettings = (id, data) => axios.post(`/admin/distributors/${id}/reward-point-settings`, data)
const updateDistributorRewardPointSettings = (id, data) => axios.put(`/admin/distributors/${id}/reward-point-settings`, data)
const fetchDistributorCoreOrderSettings = id => axios.get(`/admin/distributors/${id}/core-order-settings`)
const storeDistributorBlacklistSettings = (id, data) => axios.post(`/admin/distributors/${id}/blacklist-settings`, data)
const updateDistributorBlacklistSettings = (id, data) => axios.put(`/admin/distributors/${id}/blacklist-settings`, data)
const storeDistributorCoreOrderSettings = (id, data) => axios.post(`/admin/distributors/${id}/core-order-settings`, data)
const updateDistributorCoreOrderSettings = (id, data) => axios.put(`/admin/distributors/${id}/core-order-settings`, data)
const storeDistributorWhitelistSettings = (id, data) => axios.post(`/admin/distributors/${id}/whitelist-settings`, data)
const updateDistributorWhitelistSettings = (id, data) => axios.put(`/admin/distributors/${id}/whitelist-settings`, data)
const fetchDistributorBrand = () => axios.get('/admin/core/v1/products/stats/brand?status=published&city_id=1&take=999')
const fetchDistributorCategory = () => axios.get('/admin/core/v1/products/stats/category?&take=999')
const fetchDistributorChannelNote = () => axios.get('/admin/core/channel-admin/channel-notes?&take=999')

const fetchDistributorPricingSettings = id => axios.get(`/admin/distributors/${id}/pricing-settings`)
const storeDistributorPricingSettings = (id, data) => axios.post(`/admin/distributors/${id}/pricing-settings`, data)
const updateDistributorPricingSettings = (id, data) => axios.put(`/admin/distributors/${id}/pricing-settings`, data)

const fetchDistributorInvoiceSettings = id => axios.get(`/admin/distributors/${id}/invoice-settings`)
const storeDistributorInvoiceSettings = (id, data) => axios.post(`/admin/distributors/${id}/invoice-settings`, data)
const updateDistributorInvoiceSettings = (id, data) => axios.put(`/admin/distributors/${id}/invoice-settings`, data)

const fetchDistributorUsage = (id, queryParams) =>
  axios.get(`/admin/distributors/${id}/usage`, {
    params: queryParams,
  })

const fetchRewards = (id, queryParams) =>
  axios.get(`/admin/distributors/${id}/reward-campaigns`, {
    params: queryParams,
  })
const storeReward = (id, data) => axios.post(`/admin/distributors/${id}/reward-campaigns`, data)
const updateReward = (id, campaignId, data) => axios.put(`/admin/distributors/${id}/reward-campaigns/${campaignId}`, data)
const destroyReward = (id, campaignId) => axios.delete(`/admin/distributors/${id}/reward-campaigns/${campaignId}`)

export {
  deleteDistributor,
  deleteDistributorSettings,
  destroyReward,
  fetchDistributor,
  fetchDistributorBlacklistSettings,
  fetchDistributorBrand,
  fetchDistributorCategory,
  fetchDistributorChannelNote,
  fetchDistributorCoreOrderSettings,
  fetchDistributorFinancialSettings,
  fetchDistributorInvoiceSettings,
  fetchDistributorMedicalCardSettings,
  fetchDistributorPricingSettings,
  fetchDistributorRewardPointSettings,
  fetchDistributorSettings,
  fetchDistributorSettingsKeyMap,
  fetchDistributorUsage,
  fetchDistributorWhitelistSettings,
  fetchDistributors,
  fetchRewards,
  fetchtDistributorBalance,
  storeDistributor,
  storeDistributorBlacklistSettings,
  storeDistributorCoreOrderSettings,
  storeDistributorFinancialSettings,
  storeDistributorInvoiceSettings,
  storeDistributorMedicalCardSettings,
  storeDistributorPricingSettings,
  storeDistributorRewardPointSettings,
  storeDistributorSettings,
  storeDistributorWhitelistSettings,
  storeReward,
  updateDistributor,
  updateDistributorBlacklistSettings,
  updateDistributorCoreOrderSettings,
  updateDistributorFinancialSettings,
  updateDistributorInvoiceSettings,
  updateDistributorMedicalCardSettings,
  updateDistributorPricingSettings,
  updateDistributorProfile,
  updateDistributorProfilePhoto,
  updateDistributorRewardPointSettings,
  updateDistributorSettings,
  updateDistributorWhitelistSettings,
  updateReward,
}
