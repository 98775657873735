<template>
  <v-navigation-drawer
    :value="active"
    temporary
    touchless
    :right="!$vuetify.rtl"
    :width="$vuetify.breakpoint.smAndUp ? 480 : '100%'"
    app
    @input="(val) => $emit('aside', val)"
  >
    <user-form
      :resource="resource"
      :distributor="distributor"
      :loading="loading"
      :errors="errors"
      @submit="handleSubmit"
    >
    </user-form>
  </v-navigation-drawer>
</template>

<script>
import { useExtractErrors } from '@/composables'
import { storeUser, updateUser } from '@api/user'
import { mdiClose } from '@mdi/js'
import { ref } from '@vue/composition-api'
import UserForm from './UserForm.vue'

export default {
  components: { UserForm },
  model: {
    prop: 'active',
    event: 'aside',
  },
  props: {
    active: {
      type: Boolean,
      required: true,
    },
    resource: {
      type: [Boolean, Object],
      default: false,
    },
    distributor: {
      type: Object,
      default: null,
    },
  },
  setup(props, { emit }) {
    const loading = ref(false)
    const errors = ref([])
    const syncAble = ref(false)
    const userData = ref(props.resource)

    const handleSubmit = async form => {
      loading.value = true
      const request = props.resource ? updateUser(props.resource.id, { ...form }) : storeUser({ ...form })

      await request
        .then(async res => {
          userData.value = res.data.data
          location.reload()
        })
        .catch(err => {
          errors.value = useExtractErrors(err)
        })
      loading.value = false
    }

    const changed = () => {
      emit('changed', userData.value)
    }

    return {
      handleSubmit,
      loading,
      errors,

      syncAble,
      userData,
      changed,

      icons: {
        mdiClose,
      },
    }
  },
}
</script>
